import { Box, Grid, Paper, styled } from "@mui/material";
import ProductionLine from "./ProductionLine";
import { DailyReviewPresenter } from "../dailyReview/dailyReview-presenter";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../common/useAxiosPrivate";
import PreparationLine from "./PreparationLine";


export default function ProductionReview() {
    const axiosPrivate = useAxiosPrivate();
    let dailyReviewPresenter = new DailyReviewPresenter(axiosPrivate);
    const [productionLineForMain, setProductionLineDataorMain] = useState([]);
    const [productionLineForCRV, setProductionLineDataorCRV] = useState([]);

    const fetchProductionLineData = async () => {
        try {
            const responseDataForMain = await dailyReviewPresenter.fetchProductionReviewData("main");
            
            const responseDataForCRV = await dailyReviewPresenter.fetchProductionReviewData("crv");
            setProductionLineDataorMain(responseDataForMain);
            setProductionLineDataorCRV(responseDataForCRV)
        } catch (error) {
            console.log("Error", error);
        }
    };

    useEffect(() => {
      fetchProductionLineData();
      console.log(productionLineForMain);
    }, []);

    return (
      <Box sx={{ flexGrow: 1, mt: '5px' }}>
        <Grid container spacing={3}>
          <Grid item xs>
            <h2 className="text-center">Main Line</h2>
            <ProductionLine productionLineData={productionLineForMain} />
          </Grid>
          <Grid item xs={7}>
            <h2 className="text-center">Preapartion</h2>
            <PreparationLine />
            {/* <Grid container spacing={2}>
              <Grid item xs>
                    <PreparationLine />
                </Grid>
                <Grid item xs>
                    <PreparationLine />
                </Grid>
            </Grid> */}
          </Grid>
          <Grid item xs>
            <h2 className="text-center">CRV Line</h2>
            <ProductionLine productionLineData={productionLineForCRV} />
          </Grid>
        </Grid>
      </Box>
    );
}
