import {
  Checkbox,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { IUserInfo } from "../../../../store/actions";
import _ from "lodash";
import useAxiosPrivate from "../../../../common/useAxiosPrivate";
// import httpClient from "../../../../https/httpClient";
const { REACT_APP_API_BASE_URL } = process.env;

const prepTypes = {
  chassis_prep: 0,
  wall_assembly_prep: 1,
  roof_assembly_prep: 2,
  doors_and_drawers: 3,
  furniture_prep: 4,
  cnc_prep: 5,
};
export default function Preparation() {
  const authInfo: IUserInfo = useSelector(
    (state: any) => state.userAuthInfoState
  );
  const [allPrepData, setALLPrepData] = useState<any>([]);
  const [loader, setLoader] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const stationAlertOptions = [
    { label: "Select", value: "" },
    { label: "Alert", value: "Alert" },
    { label: "Work Active", value: "Work Active" },
    { label: "Work Complete", value: "Work Complete" },
    { label: "Stores", value: "Stores" },
    { label: "Maintenance", value: "Maintenance" },
  ];
  const cncMachineOptions = [
    { label: "Select", value: "" },
    { label: "Woodtron 3", value: "Woodtron 3" },
    { label: "Woodtron 4", value: "Woodtron 4" },
    { label: "Woodtron 5", value: "Woodtron 5" },
  ];

  const getPreparationData = async () => {
    const result = await axiosPrivate.get(
      `get_all_chassis_preparation_queue_report`
    );

    let formatedData: any = {};
    _.forIn(
      _.groupBy(result.data, (d) => {
        return d.stationName;
      }),
      function (value, key) {
        let obj: any = {};
        _.each(value, (v) => {
          if (
            !v.chassisPreparationQueues ||
            v.chassisPreparationQueues.length == 0
          ) {
            obj[v.line] = [
              {
                id: "",
                chassisNo: "",
                checked: false,
                model: "",
                priorityNo: "",
                stationAlert: "",
                cncMachine: "",
                uploadedToCncMachine: false,
              },
            ];
          } else {
            obj[v.line] = _.map(v.chassisPreparationQueues, (q) => {
              return {
                id: v.id,
                chassisNo: q.chassisNo,
                checked: q.checked,
                model: q.model,
                priorityNo: q.priorityNo,
                stationAlert: q.stationAlert,
                cncMachine: q.cncMachine,
                uploadedToCncMachine: q.uploadedToCncMachine,
              };
            });
          }
        });
        formatedData[key] = [obj];
      }
    );
    setALLPrepData([formatedData]);
  };

  useEffect(() => {
    getPreparationData();
    let id = setInterval(() => {
      getPreparationData();
    }, 15 * 1000);
    return () => clearInterval(id);
  }, []);

  const handleAlertOptionsChange = async (row: any, value: any) => {
    let requestBody = {
      chassisNo: row.chassisNo,
      stationAlert: value,
    };
    try {
      await axiosPrivate.put(
        `update_station_alert_to_preparation_queue/${row.id}`,
        requestBody
      );
      await getPreparationData();
      toast.success("Saved Successfully!");
    } catch (e) {
      toast.error("Failed!");
    }
  };

  const handleCncMachineOptionsChange = async (row: any, value: any) => {
    let requestBody = {
      chassisNo: row.chassisNo,
      cncMachine: value,
    };
    try {
      await axiosPrivate.put(
        `update_cnc_machine_to_preparation_queue/${row.id}`,
        requestBody
      );
      await getPreparationData();
      toast.success("Saved Successfully!");
    } catch (e) {
      toast.error("Failed!");
    }
  }

  const isAdmin =
    authInfo.roles &&
    (authInfo.roles.includes("user.admin") ||
      authInfo.roles?.includes("production-manager"));

  const prepType = isAdmin
    ? null
    : (Object.keys(prepTypes).find((key) =>
        authInfo.roles?.includes(key)
      ) as keyof typeof prepTypes);

  const getBackgroundColor = (isAdmin: any, stationAlert: any) => {
    // if (isAdmin && stationAlert === "Alert") {
    //   return "#FF0000";
    // } else if (isAdmin && stationAlert === "Work Active") {
    //   return "#FFA500";
    // } else if (isAdmin && stationAlert === "Work Complete") {
    //   return "#008000";
    // } else if (isAdmin && stationAlert === "Stores") {
    //   return "#0000FF";
    // } else if (isAdmin && stationAlert === "Maintenance") {
    //   return "inherit";
    // } else {
    //   return "inherit";
    // }

    if (!isAdmin) return "inherit";

    const colorMap: Record<string, string> = {
      "Alert": "#FF0000",
      "Work Active": "#FFA500",
      "Work Complete": "#008000",
      "Stores": "#0000FF",
      "Maintenance": "inherit",
    };

    return colorMap[stationAlert] || "inherit";
  };

  const getTextColor = (isAdmin: any, stationAlert: any) => {
    if (!isAdmin) return "inherit";

    const colorMap: Record<string, string> = {
      "Alert": "#ffffff",
      "Work Active": "#ffffff",
      "Work Complete": "#ffffff",
      "Stores": "#ffffff",
      "Maintenance": "inherit",
    };

    return colorMap[stationAlert] || "inherit";
  };

  const PreperationTableHead = ({isStationCnc}: {isStationCnc: boolean}) => (
    <TableHead>
      <TableRow style={{ backgroundColor: "rgb(59, 17, 80)" }}>
        <TableCell style={{ width: "120px" }}>
          <Typography
            variant="subtitle1"
            style={{
              color: "#fff",
              fontFamily: "League Spartan, sans-serif",
            }}
          >
            Station Name
          </Typography>
        </TableCell>
        <TableCell style={{ width: "80px" }}>
          <Typography
            variant="subtitle1"
            style={{
              color: "#fff",
              fontFamily: "League Spartan, sans-serif",
            }}
          >
            Line View
          </Typography>
        </TableCell>
        <TableCell style={{ width: "100px" }}>
          <Typography
            variant="subtitle1"
            style={{
              color: "#fff",
              fontFamily: "League Spartan, sans-serif",
            }}
          >
            Priority No
          </Typography>
        </TableCell>
        {isStationCnc?(
        <TableCell style={{ width: "100px" }}>
          <Typography
            variant="subtitle1"
            style={{
              color: "#fff",
              fontFamily: "League Spartan, sans-serif",
            }}
          >
            CNC
          </Typography>
        </TableCell>
        ):null}
        <TableCell style={{ width: "100px" }}>
          <Typography
            variant="subtitle1"
            style={{
              color: "#fff",
              fontFamily: "League Spartan, sans-serif",
            }}
          >
            Chassis No
          </Typography>
        </TableCell>
        {(prepType || isAdmin) && (
          <TableCell style={{ width: "110px" }}>
            <Typography
              variant="subtitle1"
              style={{
                color: "#fff",
                fontFamily: "League Spartan, sans-serif",
              }}
            >
              Station Alert
            </Typography>
          </TableCell>
        )}
        <TableCell style={{ width: "220px" }}>
          <Typography
            variant="subtitle1"
            style={{
              color: "#fff",
              fontFamily: "League Spartan, sans-serif",
            }}
          >
            Model
          </Typography>
        </TableCell>
        <TableCell style={{ width: "180px" }}>
          <Typography
            variant="subtitle1"
            style={{
              color: "#fff",
              fontFamily: "League Spartan, sans-serif",
            }}
          >
            Notes
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );

  // enable enc machine column for only cnc_prep station
  let isStationCnc:boolean = false;
  if(prepType?.toLowerCase() === "cnc_prep"){
    isStationCnc = true;
  }

  return (
    <div
      className="p-0 ms-0"
      style={{ textAlign: "center", maxWidth: "1150px" }}
    >
      {isAdmin || prepType ? (
        <>
          <TableContainer component={Paper} style={{ marginTop: "8px" }}>
            <Table size="small">
              <PreperationTableHead isStationCnc={isStationCnc} />
              <TableBody>
                {loader ? (
                  <Typography className="loading">Loading...</Typography>
                ) : (
                  <>
                    {allPrepData && allPrepData.length > 0 ? (
                      Object.keys(allPrepData[0]).map(
                        (station: string, i: number) => (
                          <React.Fragment key={i}>
                            {(isAdmin ||
                              (prepType && i === prepTypes[prepType])) &&
                              Object.keys(allPrepData[0][station][0]).map(
                                (line: any, idx: number) =>
                                  allPrepData[0][station][0][line].map(
                                    (row: any, index: number) => (
                                      <TableRow
                                        key={index}
                                        style={{
                                          borderBottom:
                                            (line === "main" &&
                                              index ===
                                                allPrepData[0][station][0][
                                                  "main"
                                                ].length -
                                                  1) ||
                                            (idx === 1 &&
                                              index ===
                                                allPrepData[0][station][0][
                                                  "crv"
                                                ].length -
                                                  1 &&
                                              isAdmin)
                                              ? "4px solid #d0d0d0"
                                              : "inherit",
                                        }}
                                      >
                                        {index === 0 && idx === 0 && (
                                          <TableCell
                                            style={{ width: "180px" }}
                                            rowSpan={
                                              allPrepData[0][station][0]["main"]
                                                ?.length +
                                              allPrepData[0][station][0]["crv"]
                                                ?.length
                                            }
                                          >
                                            <Typography
                                              variant="subtitle1"
                                              style={{
                                                fontWeight: 500,
                                                fontFamily:
                                                  "League Spartan, sans-serif",
                                              }}
                                            >
                                              {station}
                                            </Typography>
                                          </TableCell>
                                        )}
                                        {index === 0 && (
                                          <TableCell
                                            style={{
                                              width: "180px",
                                              textTransform: "uppercase",
                                            }}
                                            rowSpan={
                                              allPrepData[0][station][0][line]
                                                ?.length || 1
                                            }
                                          >
                                            <Typography
                                              variant="subtitle1"
                                              style={{
                                                width: "80px",
                                                color:
                                                  line === "crv"
                                                    ? "blue"
                                                    : "black",
                                                fontWeight: 500,
                                                fontFamily:
                                                  "League Spartan, sans-serif",
                                              }}
                                            >
                                              {line}
                                            </Typography>
                                          </TableCell>
                                        )}
                                        <TableCell
                                          style={{
                                            width: "120px",
                                            height: "30px",
                                            backgroundColor: getBackgroundColor(
                                              isAdmin,
                                              row?.stationAlert
                                            ),
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle1"
                                            style={{
                                              color: getTextColor(isAdmin, row?.stationAlert),
                                              fontWeight: 500,
                                              fontFamily:
                                                "League Spartan, sans-serif",
                                            }}
                                          >
                                            {/* {prepType && (
                                              <Checkbox
                                                checked={row?.checked}
                                                onChange={async (event) => {
                                                  handleCheckboxChange(
                                                    event,
                                                    row
                                                  );
                                                }}
                                              />
                                            )} */}
                                            {row?.priorityNo}
                                          </Typography>
                                        </TableCell>

                                        {/* CNC Machine block start */}
                                        {isStationCnc?(
                                        <TableCell
                                          style={{
                                            width: "110px",
                                            height: "30px",
                                            backgroundColor: getBackgroundColor(
                                              isAdmin,
                                              row?.stationAlert
                                            ),
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle1"
                                            style={{
                                              color: getTextColor(isAdmin, row?.stationAlert),
                                              fontWeight: 500,
                                              fontFamily:
                                                "League Spartan, sans-serif",
                                            }}
                                          >
                                            <Select
                                                name="cncMachineDropDown"
                                                value={row?.cncMachine ?? ""}
                                                onChange={(event) =>
                                                  handleCncMachineOptionsChange(
                                                    row,
                                                    event.target.value
                                                  )
                                                }
                                                className="station_alert_dropdown"
                                                displayEmpty
                                                inputProps={{
                                                  "aria-label": "Without label",
                                                }}
                                                style={{
                                                  width: "100%",
                                                  fontFamily:
                                                    "League Spartan, sans-serif",
                                                  backgroundColor:"transparent",
                                                  color:"black",
                                                }}
                                              >
                                                {cncMachineOptions.map(
                                                  (item) => (
                                                    <MenuItem
                                                      value={item.value}
                                                      style={{
                                                        fontFamily:
                                                          "League Spartan, sans-serif",
                                                      }}
                                                    >
                                                      {item.label}
                                                    </MenuItem>
                                                  )
                                                )}
                                              </Select>
                                          </Typography>
                                        </TableCell>):null}
                                        {/* CNC Machine block ends */}

                                        <TableCell
                                          style={{
                                            width: "110px",
                                            height: "30px",
                                            backgroundColor: getBackgroundColor(
                                              isAdmin,
                                              row?.stationAlert
                                            ),
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle1"
                                            style={{
                                              color: getTextColor(isAdmin, row?.stationAlert),
                                              fontWeight: 500,
                                              fontFamily:
                                                "League Spartan, sans-serif",
                                            }}
                                          >
                                            {row?.chassisNo}
                                          </Typography>
                                        </TableCell>
                                        {prepType ? (
                                          <TableCell
                                            style={{
                                              width: "180px",
                                              height: "30px",
                                              backgroundColor:
                                                getBackgroundColor(
                                                  isAdmin,
                                                  row?.stationAlert
                                                ),
                                            }}
                                          >
                                            <Typography
                                              variant="body1"
                                              style={{
                                                fontFamily:
                                                  "League Spartan, sans-serif",
                                              }}
                                            >
                                              <Select
                                                name="stationAlert"
                                                value={row?.stationAlert ?? ""}
                                                onChange={(event) =>
                                                  handleAlertOptionsChange(
                                                    row,
                                                    event.target.value
                                                  )
                                                }
                                                className="station_alert_dropdown"
                                                displayEmpty
                                                inputProps={{
                                                  "aria-label": "Without label",
                                                }}
                                                style={{
                                                  width: "100%",
                                                  fontFamily:
                                                    "League Spartan, sans-serif",
                                                  backgroundColor:
                                                    row?.stationAlert ===
                                                    "Alert"
                                                      ? "#FF0000"
                                                      : row?.stationAlert ===
                                                        "Work Active"
                                                      ? "#FFA500"
                                                      : row?.stationAlert ===
                                                        "Work Complete"
                                                      ? "#008000"
                                                      : row?.stationAlert ===
                                                        "Stores"
                                                      ? "#0000FF"
                                                      : row?.stationAlert ===
                                                        "Maintenance"
                                                      ? "#FFFFFF"
                                                      : "transparent",
                                                  color:
                                                    row?.stationAlert ===
                                                      "Alert" ||
                                                    row?.stationAlert ===
                                                      "Work Active" ||
                                                    row?.stationAlert ===
                                                      "Work Complete" ||
                                                    row?.stationAlert ===
                                                      "Stores"
                                                      ? "white"
                                                      : row?.stationAlert ===
                                                        "Maintenance"
                                                      ? "black"
                                                      : "black",
                                                }}
                                              >
                                                {stationAlertOptions.map(
                                                  (item) => (
                                                    <MenuItem
                                                      value={item.value}
                                                      style={{
                                                        fontFamily:
                                                          "League Spartan, sans-serif",
                                                      }}
                                                    >
                                                      {item.label}
                                                    </MenuItem>
                                                  )
                                                )}
                                              </Select>
                                            </Typography>
                                          </TableCell>
                                        ) : isAdmin ? (
                                          <TableCell
                                            style={{
                                              width: "180px",
                                              height: "30px",
                                              backgroundColor: getBackgroundColor(isAdmin, row?.stationAlert),
                                            }}
                                          >
                                            <Typography
                                              variant="body1"
                                              style={{
                                                color: getTextColor(isAdmin, row?.stationAlert),
                                                fontFamily:
                                                  "League Spartan, sans-serif",
                                                fontWeight: 500,
                                              }}
                                            >
                                              {row?.stationAlert ?? ""}
                                            </Typography>
                                          </TableCell>
                                        ) : (
                                          <></>
                                        )}
                                        <TableCell
                                          style={{
                                            width: "180px",
                                            height: "30px",
                                            backgroundColor: getBackgroundColor(
                                              isAdmin,
                                              row?.stationAlert
                                            ),
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle1"
                                            style={{
                                              color: getTextColor(isAdmin, row?.stationAlert),
                                              fontFamily:
                                                "League Spartan, sans-serif",
                                              fontWeight: 500,
                                            }}
                                          >
                                            {row?.model}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            width: "180px",
                                            height: "30px",
                                            backgroundColor: getBackgroundColor(
                                              isAdmin,
                                              row?.stationAlert
                                            ),
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle1"
                                            style={{
                                              color: getTextColor(isAdmin, row?.stationAlert),
                                              fontWeight: 500,
                                              fontFamily:
                                                "League Spartan, sans-serif",
                                            }}
                                          >
                                            {row?.notes}
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )
                              )}
                          </React.Fragment>
                        )
                      )
                    ) : (
                      <Typography>No Data Found</Typography>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Typography
          variant="body1"
          style={{ marginTop: "8px", fontFamily: "League Spartan, sans-serif" }}
        >
          Not a valid role!!!
        </Typography>
      )}
    </div>
  );
}
