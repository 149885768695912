import { ITableColumn } from "../dailyReview/dailyReview-models";


  export class ProductionReviewPresenter {
    productionLineTableHeader: ITableColumn[] = [
      { col_name: "Station Name", width: "220px" },
      { col_name: "Chassis No", width: "110px" },
      { col_name: "Station Alert", width: "140px" },
      { col_name: "Model", width: "180px" },
      { col_name: "Notes", width: "80px" }
    ];
  }