import {
  IInStockAndDeliveredUpdateModel,
  IUpdateProcessDataModel,
} from "../../../generated/generated-proxies";
import { ITableColumn } from "./dailyReview-models";
import DailyReviewService from "./dailyReview-service";

export class DailyReviewPresenter {
  private axiosPrivate: any = null;
  private dailyReviewServiceProvider: DailyReviewService;
  constructor(axiosPrivate: any) {
    this.axiosPrivate = axiosPrivate;
    this.dailyReviewServiceProvider = new DailyReviewService(this.axiosPrivate);
  }
  assemblyTableHeader: ITableColumn[] = [
    { col_name: "Station Name", width: "200px" },
    { col_name: "Priority No", width: "110px" },
    { col_name: "Chassis No", width: "110px" },
    { col_name: "Station Alert", width: "180px" },
    { col_name: "Model", width: "190px" },
    { col_name: "Notes", width: "200px" },
    { col_name: "On Hold", width: "100px" },
  ];
  //----------------- Assembly ------------------
  formatCompletedItemsData = (data: any) => {
    let text = "";
    data.forEach((item: any) => {
      text += item?.priorityNo + ")" + item?.chassisNo + ", ";
    });
    if (text.endsWith(", ")) {
      text = text.slice(0, -2);
    }
    return text;
  };

  parseProductionReviewResponse = (data: any) => {
    const parsedData = data.map((str: any) => {
      try {
        return JSON.parse(str.replace(/'/g, '"'));
      } catch (error) {
        console.error("Error parsing JSON string:", error);
        return [];
      }
    });
    return parsedData;
  };

  groupRowsByStation = (data: any) => {
    const groupedData: any = {};
    data.forEach((row: any) => {
      const stationName = row.station_name;
      if (!groupedData[stationName]) {
        groupedData[stationName] = [];
      }
      groupedData[stationName].push(row);
    });
    return groupedData;
  };

  prepareProductionReviewData(data: any) {
    if (Array.isArray(data) && data) {
      const parsedContent = this.parseProductionReviewResponse(data);
      const filteredContent = parsedContent.filter(
        (item: any) => item !== null
      );
      const groupedData = this.groupRowsByStation(filteredContent);
      return groupedData;
    } else {
      return [];
    }
  }

  async fetchProductionReviewData(lineValue: string) {
    const response =
      await this.dailyReviewServiceProvider.fetchProductionReviewData(
        lineValue
      );
    return response;
    // console.log(this.prepareProductionReviewData(response.response_content));
    // return this.prepareProductionReviewData(response.response_content);
  }

  async fetchCompletedItems(lineValue: string) {
    const response = await this.dailyReviewServiceProvider.fetchCompletedItems(
      lineValue
    );
    const formatData = this.formatCompletedItemsData(response);
    return formatData;
  }

  async fetchSimulatedData(lineValue: string) {
    const response = await this.dailyReviewServiceProvider.fetchSimulatedData(
      lineValue
    );
    return response;
  }

  async updateSimulatorJsonData(
    lineVal: string,
    rowName: string,
    data: IInStockAndDeliveredUpdateModel
  ) {
    const response =
      await this.dailyReviewServiceProvider.updateSimulatorJsonData(
        lineVal,
        rowName,
        data
      );
    return response;
  }

  async updateProcessedData(id: any, reqBody: any) {
    const response = await this.dailyReviewServiceProvider.updateProcessedData(
      id,
      reqBody
    );
    return response;
  }

  // -------------- Preparation --------------------
  async getPdfUrl(pdfUrl: string) {
    const blob = await this.dailyReviewServiceProvider.fetchPdfUrlToBlob(
      pdfUrl
    );
    this.openPdfInNewWindow(blob);
  }

  openPdfInNewWindow(blob: any) {
    const url = window.URL.createObjectURL(blob);
    const newWindow = window.open(url);
    if (!newWindow) return;
    newWindow.onload = function () {
      newWindow.print();
    };
  }
}
