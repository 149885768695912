import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import TableHeader from "./../dailyReview/components/ProductionTableHeader/TableHeader";
import { ProductionReviewPresenter } from "./ProductionReviewPresenter";
import { Fragment } from "react";

export default function ProductionLine(props: any) {
  const { productionLineData } = props;
  let productionReviewPresenter = new ProductionReviewPresenter();
  console.log(productionLineData);
  return (
    <Container>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHeader
            headers={productionReviewPresenter.productionLineTableHeader}
          ></TableHeader>
          <TableBody>
            {productionLineData.length > 0 ? (
              <>
                <Fragment>
                  {productionLineData.map((station: any, index: any) => (
                    <TableRow
                      key={index}
                      style={{
                        background: `${
                          index % 2 === 0 ? "#eaeae8" : "#F9F9F9"
                        }`,
                        borderTop: `${
                          index === 0 && index === productionLineData.length - 1
                            ? "20px solid #ffff"
                            : "inherit"
                        }`,
                      }}
                    >
                      <TableCell>
                        <Typography
                          variant="body1"
                          style={{
                            fontFamily: "League Spartan, sans-serif",
                          }}
                        >
                          {station.lineNumber.toString().padStart(2, "0")}{" "}
                          {station.stationName}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          style={{
                            fontFamily: "League Spartan, sans-serif",
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          {station.chassisNo && station.priorityNo
                            ? `${station.priorityNo})${station.chassisNo}`
                            : ""}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          style={{
                            fontFamily: "League Spartan, sans-serif",
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          {/* {station?.stationAlert?.trim()
                            ? station.stationAlert
                            : "None"} */}
                          {station?.stationAlert ?? ""}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          style={{
                            fontFamily: "League Spartan, sans-serif",
                          }}
                        >
                          {station.model}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          style={{
                            fontFamily: "League Spartan, sans-serif",
                          }}
                        >
                          {station.notes}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </Fragment>
              </>
            ) : (
              <>
                <Typography
                  variant="body1"
                  className="loading text-start py-1 ps-3"
                >
                  Data not Found
                </Typography>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
