import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import useAxiosPrivate from "../../common/useAxiosPrivate";
import { IUserInfo } from "../../store/actions";
export default function PreparationLine() {
  const authInfo: IUserInfo = useSelector(
    (state: any) => state.userAuthInfoState
  );
  const [allPrepData, setALLPrepData] = useState<any>([]);
  const axiosPrivate = useAxiosPrivate();

  const getPreparationData = async () => {
    const result = await axiosPrivate.get(
      `get_all_chassis_preparation_queue_report`
    );

    let formatedData: any = {};
    _.forIn(
      _.groupBy(result.data, (d: any) => {
        return d.stationName;
      }),
      function (value, key) {
        let obj: any = {};
        _.each(value, (v) => {
          if (
            !v.chassisPreparationQueues ||
            v.chassisPreparationQueues.length == 0
          ) {
            obj[v.line] = [
              {
                id: "",
                chassisNo: "",
                checked: false,
                model: "",
                priorityNo: "",
                stationAlert: "",
              },
            ];
          } else {
            obj[v.line] = _.map(v.chassisPreparationQueues, (q) => {
              return {
                id: v.id,
                chassisNo: q.chassisNo,
                checked: q.checked,
                model: q.model,
                priorityNo: q.priorityNo,
                stationAlert: q.stationAlert,
              };
            });
          }
        });
        formatedData[key] = [obj];
      }
    );
    setALLPrepData([formatedData]);
  };

  useEffect(() => {
    getPreparationData();
    let id = setInterval(() => {
      getPreparationData();
    }, 10 * 1000);
    return () => clearInterval(id);
  }, []);

  const PreperationTableHead = () => (
    <TableHead>
      <TableRow style={{ backgroundColor: "rgb(59, 17, 80)" }}>
        <TableCell style={{ width: "120px" }}>
          <Typography
            variant="subtitle1"
            style={{
              color: "#fff",
              fontFamily: "League Spartan, sans-serif",
            }}
          >
            Station Name
          </Typography>
        </TableCell>
        <TableCell style={{ width: "80px" }}>
          <Typography
            variant="subtitle1"
            style={{
              color: "#fff",
              fontFamily: "League Spartan, sans-serif",
            }}
          >
            Line View
          </Typography>
        </TableCell>
        <TableCell style={{ width: "120px" }}>
          <Typography
            variant="subtitle1"
            style={{
              color: "#fff",
              fontFamily: "League Spartan, sans-serif",
            }}
          >
            Priority No
          </Typography>
        </TableCell>
        <TableCell style={{ width: "100px" }}>
          <Typography
            variant="subtitle1"
            style={{
              color: "#fff",
              fontFamily: "League Spartan, sans-serif",
            }}
          >
            Chassis No
          </Typography>
        </TableCell>
        <TableCell style={{ width: "200px" }}>
          <Typography
            variant="subtitle1"
            style={{
              color: "#fff",
              fontFamily: "League Spartan, sans-serif",
            }}
          >
            Model
          </Typography>
        </TableCell>
        <TableCell style={{ width: "210px" }}>
          <Typography
            variant="subtitle1"
            style={{
              color: "#fff",
              fontFamily: "League Spartan, sans-serif",
            }}
          >
            Notes
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );

  const roleOperationManager = authInfo.roles && authInfo.roles?.includes("operation-manager");

  return (
    <Grid container spacing={2}>
      {roleOperationManager ? (
        <>
          {allPrepData && allPrepData.length > 0 ? (
            Object.keys(allPrepData[0]).map((station: string, i: number) => (
              <React.Fragment key={i}>
                <Grid item xs={6}>
                  <TableContainer
                    component={Paper}
                    style={{ marginTop: "8px" }}
                  >
                    <Table size="small">
                      <PreperationTableHead />
                      <TableBody>
                        {roleOperationManager &&
                          Object.keys(allPrepData[0][station][0]).map(
                            (line: any, idx: number) =>
                              allPrepData[0][station][0][line].map(
                                (row: any, index: number) => (
                                  <>
                                    <TableRow
                                      key={index}
                                      style={{
                                        borderBottom:
                                          (line === "main" &&
                                            index ===
                                              allPrepData[0][station][0]["main"]
                                                .length -
                                                1) ||
                                          (idx === 1 &&
                                            index ===
                                              allPrepData[0][station][0]["crv"]
                                                .length -
                                                1 &&
                                            roleOperationManager)
                                            ? "4px solid #d0d0d0"
                                            : "inherit",
                                      }}
                                    >
                                      {index === 0 && idx === 0 && (
                                        <TableCell
                                          style={{ width: "180px" }}
                                          rowSpan={
                                            allPrepData[0][station][0]["main"]
                                              ?.length +
                                            allPrepData[0][station][0]["crv"]
                                              ?.length
                                          }
                                        >
                                          <Typography
                                            variant="subtitle1"
                                            style={{
                                              fontWeight: 500,
                                              fontFamily:
                                                "League Spartan, sans-serif",
                                            }}
                                          >
                                            {station}
                                          </Typography>
                                        </TableCell>
                                      )}
                                      {index === 0 && (
                                        <TableCell
                                          style={{
                                            width: "180px",
                                            textTransform: "uppercase",
                                          }}
                                          rowSpan={
                                            allPrepData[0][station][0][line]
                                              ?.length || 1
                                          }
                                        >
                                          <Typography
                                            variant="subtitle1"
                                            style={{
                                              width: "80px",
                                              color:
                                                line === "crv"
                                                  ? "blue"
                                                  : "black",
                                              fontWeight: 500,
                                              fontFamily:
                                                "League Spartan, sans-serif",
                                            }}
                                          >
                                            {line}
                                          </Typography>
                                        </TableCell>
                                      )}
                                      <TableCell
                                        style={{
                                          width: "120px",
                                          height: "30px",
                                          backgroundColor:
                                            roleOperationManager && row?.stationAlert ===
                                                "Work Complete"
                                                ? "lightgreen"
                                                : roleOperationManager &&
                                                  row?.stationAlert ===
                                                    "Work Active"
                                                ? "#ffa500"
                                              : "inherit",
                                        }}
                                      >
                                        <Typography
                                          variant="subtitle1"
                                          style={{
                                            color:
                                              line === "crv" ? "blue" : "black",
                                            fontWeight: 500,
                                            fontFamily:
                                              "League Spartan, sans-serif",
                                          }}
                                        >
                                          {row?.priorityNo}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: "110px",
                                          height: "30px",
                                          backgroundColor:
                                            roleOperationManager && row?.stationAlert ===
                                                "Work Complete"
                                                ? "lightgreen"
                                                : roleOperationManager &&
                                                  row?.stationAlert ===
                                                    "Work Active"
                                                ? "#ffa500"
                                              : "inherit",
                                        }}
                                      >
                                        <Typography
                                          variant="subtitle1"
                                          style={{
                                            color:
                                              line === "crv" ? "blue" : "black",
                                            fontWeight: 500,
                                            fontFamily:
                                              "League Spartan, sans-serif",
                                          }}
                                        >
                                          {row?.chassisNo}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: "180px",
                                          height: "30px",
                                          backgroundColor:
                                            roleOperationManager && row?.stationAlert ===
                                                "Work Complete"
                                                ? "lightgreen"
                                                : roleOperationManager &&
                                                  row?.stationAlert ===
                                                    "Work Active"
                                                ? "#ffa500"
                                              : "inherit",
                                        }}
                                      >
                                        <Typography
                                          variant="subtitle1"
                                          style={{
                                            color:
                                              line === "crv" ? "blue" : "black",
                                            fontFamily:
                                              "League Spartan, sans-serif",
                                            backgroundColor:
                                              roleOperationManager && row?.checked
                                                ? "lightgreen"
                                                : "inherit",
                                            fontWeight: 500,
                                          }}
                                        >
                                          {row?.model}
                                        </Typography>
                                      </TableCell>

                                      <TableCell
                                        style={{
                                          width: "180px",
                                          height: "30px",
                                          backgroundColor:
                                            roleOperationManager && row?.stationAlert ===
                                                "Work Complete"
                                                ? "lightgreen"
                                                : roleOperationManager &&
                                                  row?.stationAlert ===
                                                    "Work Active"
                                                ? "#ffa500"
                                              : "inherit",
                                        }}
                                      >
                                        <Typography
                                          variant="subtitle1"
                                          style={{
                                            color:
                                              line === "crv" ? "blue" : "black",
                                            fontWeight: 500,
                                            fontFamily:
                                              "League Spartan, sans-serif",
                                          }}
                                        >
                                          {row?.notes}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  </>
                                )
                              )
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </React.Fragment>
            ))
          ) : (
            <Typography>No Data Found</Typography>
          )}
        </>
      ) : (
        <Typography
          variant="body1"
          style={{ marginTop: "8px", fontFamily: "League Spartan, sans-serif" }}
        >
          Not a valid role!!!
        </Typography>
      )}
    </Grid>
  );
}
